.maintenancePage {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: url('./images/mainBack.webp') center center no-repeat;
  background-size: cover;
}

.maintenancePage::after {
  content: ' ';
  display: block;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #0d0f46;
  background-position: 50% 0;
}

.maintenancePage .newLogo {
  padding: 2rem 0 0 2rem;
  position: relative;
  z-index: 9;
}

.maintenancePage p,
#pageNotExist .newLogo p {
  color: #fff !important;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.maintenancePage .newLogo .logo {
  height: 1.9rem;
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(125deg) brightness(106%)
    contrast(101%);
}

.maintenancePage h1 {
  font-size: 50px;
  font-weight: 400;
  color: #fff !important;
  text-align: center;
}

.maintenancePage .maintenance {
  filter: invert(100%) sepia(97%) saturate(16%) hue-rotate(269deg) brightness(104%)
    contrast(100%);
  width: 15rem;
}

.maintenancePage div.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 5rem;
  margin-top: -5rem;
  position: relative;
  z-index: 9;
}

.returnHome {
  border: 2px solid var(--highlight-color);
  border-radius: 4px;
  width: 30rem;
  height: 4rem;
  color: #fff !important;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  transition: all 0.4s ease-in-out;
}

.returnHome:hover {
  scale: 1.03;
  transition: all 0.4s ease-in-out;
}
