@media print {
  @page {
    size: auto;
    margin: 2cm -2cm !important;
    padding: 0;
  }

  body,
  #root {
    height: auto;
  }

  body {
    margin-top: -2cm !important;
  }

  nav,
  .mainNavigation,
  .ant-card-head,
  .noPrint,
  .ant-card-actions,
  [data-value='Náhľad Štart'],
  [data-value='Náhľad Stop'],
  #root div.ant-card-body div.ql-toolbar.ql-snow {
    display: none !important;
  }

  .ql-container.ql-snow,
  .ant-card-bordered,
  .ant-card-actions,
  .ant-card-actions > li:not(:last-child) {
    border: none !important;
  }

  .eELAZ {
    box-shadow: none !important;
  }
}
